import React from 'react'
import { GlobalStyles } from './src/styles/global'
import { MobileMenuProvider } from './src/components/MobileMenu/context'

export default ({ element, props }) => {
    return (
        <MobileMenuProvider>
            <GlobalStyles />
            {element}
        </MobileMenuProvider>
    )
}
