import { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from './utils'

/*-----------------------------------*/
/* Global Type
/*-----------------------------------*/

// Header

export const navigation = css`
    font-weight: 600; /* semibold */
    font-size: 14px;
    letter-spacing: -0.02em;
`