// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brokers-js": () => import("./../src/pages/brokers.js" /* webpackChunkName: "component---src-pages-brokers-js" */),
  "component---src-pages-business-loans-js": () => import("./../src/pages/business-loans.js" /* webpackChunkName: "component---src-pages-business-loans-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loan-selector-js": () => import("./../src/pages/loan-selector.js" /* webpackChunkName: "component---src-pages-loan-selector-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-business-loan-js": () => import("./../src/templates/business-loan.js" /* webpackChunkName: "component---src-templates-business-loan-js" */),
  "component---src-templates-default-page-js": () => import("./../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-investments-listing-js": () => import("./../src/templates/investments-listing.js" /* webpackChunkName: "component---src-templates-investments-listing-js" */),
  "component---src-templates-investments-single-js": () => import("./../src/templates/investments-single.js" /* webpackChunkName: "component---src-templates-investments-single-js" */),
  "component---src-templates-news-listing-js": () => import("./../src/templates/news-listing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-news-single-js": () => import("./../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */)
}

