import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'
import { darkBlue, darkerBlue } from './colors'
import { navigation } from './type'

const maxWidth = 1440

export const bgGradient = css`
    background-image: ${`linear-gradient(90deg, ${darkBlue} 0%, ${darkerBlue} 100%)`};
`

export const GlobalStyles = createGlobalStyle`
    html {
        font-size: 16px;

        ${media.tab`
            font-size: 15px;
        `}

        ${media.tabPor`
            font-size: 14px;
        `}

        ${media.mob`
            font-size: 13px;
        `}
    }

    html,
    body {
        ${tw`m-0 p-0 w-full h-full leading-none bg-darkBlue`};
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
    }

    a, p, div {
        /* color: black; */
    }

    b, strong {
        font-weight: 600;
    }

    p:not(:first-child) {
        ${tw`mt-4`};
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }
`

export const container = css`
    ${tw`w-full mx-auto`};
    max-width: ${maxWidth}px;
`

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    ${tw`px-16`};

    ${media.tabPor`
        ${tw`px-12`};
    `}

    ${media.mob`
        padding-left: 20px;
        padding-right: 20px;
    `}
`

export const margins = css`
    ${tw`mx-16`};

    ${media.tabPor`
        ${tw`mx-12`};
    `}

    ${media.mob`
        margin-left: 20px;
        margin-right: 20px;
    `}
`

export const button = css`
    ${tw`inline-block py-2 px-6 leading-none border border-orange text-orange`};
    font-weight: 600; /* semibold */
    font-size: 15px;
    letter-spacing: -0.02em;

    ${hoverState};
`

export const bgClipButton = css`
    ${tw`relative inline-block py-3 px-10 leading-none text-white`};
    font-weight: 600; /* semibold */
    font-size: 15px;
    letter-spacing: -0.02em;
    ${hoverState};

    span {
        ${tw`relative`};
        z-index: 1;
    }

    &::before {
        content: '';
        ${tw`absolute inset-0 w-full h-full bg-orange`};
        clip-path: polygon(
            0% 0%,
            100% 0,
            100% calc(100% - 10px),
            calc(100% - 10px) 100%,
            0% 100%
        );
    }
`

export const bgClip = css`
    ${tw`relative inline-block py-2 px-3 leading-none text-white`};

    span {
        ${tw`relative`};
        z-index: 1;
    }

    &::before {
        content: '';
        ${tw`absolute inset-0 w-full h-full bg-orange`};
        clip-path: polygon(
            0% 0%,
            100% 0,
            100% calc(100% - 10px),
            calc(100% - 10px) 100%,
            0% 100%
        );
    }
`

export const boxShadow = css`
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.15);
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`
